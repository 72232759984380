import './Sobre.css';
import { FaRocket, FaEye, FaHandHoldingHeart } from "react-icons/fa";


const Sobre = () => {

    const atributos = [
        {
            id: 1,
            title: 'Missão',
            text: 'Sermos especialistas em desenvolver soluções contábeis com excelência, buscando incessantemente auxiliar o desenvolvimento seguro, sustentável e progressivo dos nossos clientes.',
            icon: <FaRocket />
        },

        {
            id: 2,
            title: 'Visão',
            text: 'Ser vista como padrão de qualidade na prestação de serviços contábeis na região nordeste, oferecendo uma carteira de serviços exclusivos, desenvolvidos com excelência e tornando-os de fácil acesso a todos os empreendedores e empresários da região.',
            icon: <FaEye />
        },

        {
            id: 3,
            title: 'Valores',
            text: 'Integridade, Sigilo, Comprometimento, Foco no Cliente, Atenção aos Detalhes e Excelência na Prestação dos Serviços.',
            icon: <FaHandHoldingHeart />
        }
    ]

    return (
        <div id='sobre'>
            <div className='sobre__container'>
                <div className='sobre__img--container'>
                    <div className='bg-effect'></div>
                    <img src='/images/sobre/sobre-bg2.jpg' className='sobre__img' />
                    <h1 id='sobre__h1'>A LFM</h1>
                    <p className='sobre__text'>
                        Somos especialistas em soluções CONTÁBEIS, FINANCEIRAS, TRIBUTÁRIAS, TRABALHISTAS e PARALEGAIS para sua empresa, back-office completo.
                        Possuímos uma equipe formada por especialistas que buscam sempre estar atualizados com os cenários
                        empresariais/legais das empresas no Brasil e no mundo, primando pela excelência, precisão e
                        segurança dos serviços prestados, além de se destacarem por buscar soluções para a otimização
                        dos resultados dos nossos clientes. Hoje já contamos com inteligência artificial financeira, terceirização
                        atividades operacionais na área de Finanças, além de todos os outros serviços Contábeis já conhecidos.
                    </p>
                </div>

                <section id='sobre__atributos'>
                    {atributos.map((atributo) => (
                        <section key={atributo.id} className='sobre__atributo'>
                            <div className='atributo-icon'>{atributo.icon}</div>
                            <h4 className='sobre__h4'>{atributo.title}</h4>
                            <p className='sobre__p'>{atributo.text}</p>
                        </section>
                    ))}
                </section>
            </div>
        </div>
    )
}

export default Sobre;