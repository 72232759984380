import './App.css';
import Header from './components/Header';
import Home from './pages/Início';
import Sobre from './pages/Sobre';
import Blog from './pages/Blog';
import NossoApp from './pages/NossoApp';
import Footer from './components/Footer';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FifthNew from './pages/Newspaper/FifthNew';
import FourthNew from './pages/Newspaper/FourthNew';
import ThirdNew from './pages/Newspaper/SecondNew';
import SecondNew from './pages/Newspaper/ThirdNew';
import FirstNew from './pages/Newspaper/FirstNew';

function App() {
   return (
      <Router>
         <div className="App">
            <Header />
            <Routes>
               <Route path='/' element={<Home />} />
               <Route path='/Sobre' element={<Sobre />} />
               <Route path='/Blog' element={<Blog />} />
               <Route path='/FirstNew' element={<FirstNew />} />
               <Route path='/SecondNew' element={<SecondNew />} />
               <Route path='/ThirdNew' element={<ThirdNew />} />
               <Route path='/FourthNew' element={<FourthNew />} />
               <Route path='/FifthNew' element={<FifthNew />} />
               <Route path='/NossoApp' element={<NossoApp />} />
            </Routes>
            <Footer />
         </div>
      </Router>
   );
}

export default App;
