import './FifthNew.css';
import React, { useEffect } from 'react';


const FifthNew = () => {

    useEffect(() => {
        const scrollOptions = {
            top: 0,
            behavior: 'smooth'
        };

        window.scrollTo(scrollOptions);
    }, []);

    return (
        <section id='fifthNew'>
            <div className='fifthNew__container'>
                <img src='/images/blog/5.jpg' />
                <h1>Contratação de Horistas CLT: Regras e Considerações Importantes</h1>

                <div className='fifthNew__intro'>
                    <p>No mercado de trabalho, a contratação de horistas seguindo as regras da Consolidação das Leis do Trabalho (CLT) é uma prática comum e que requer atenção aos detalhes por parte dos empregadores.</p>
                    <p>Esse tipo de contratação oferece flexibilidade tanto para as empresas quanto para os trabalhadores, mas é fundamental compreender as normas estabelecidas para evitar problemas legais e garantir uma relação de trabalho justa e transparente.</p>
                </div>

                <div className='fifthNew__question'>
                    <h2>O que é um horista CLT?</h2>
                    <p>
                        Um horista CLT é um funcionário contratado por meio da Consolidação das Leis do Trabalho (CLT) para trabalhar com remuneração baseada nas horas efetivamente trabalhadas. Isso significa que o trabalhador recebe de acordo com o tempo dedicado à empresa, sem um salário fixo mensal.
                    </p>
                </div>

                <div className='fifthNew__rules'>
                    <h2>Regras para a contratação de horistas CLT:</h2>

                    <ol>
                        <li>Registro em Carteira Assinada: O empregador deve realizar o registro do trabalhador em carteira de trabalho, garantindo assim todos os direitos e benefícios previstos na legislação trabalhista.</li>
                        <li>Definição de Jornada de Trabalho: É essencial estabelecer claramente a jornada de trabalho do horista, indicando os horários de início e término, bem como os períodos de intervalo.</li>
                        <li>Pagamento de Horas Extras: Caso o horista exceda a jornada de trabalho estabelecida, as horas extras devem ser remuneradas de acordo com o que determina a legislação, com acréscimo no valor da hora trabalhada.</li>
                        <li>Descanso Semanal Remunerado: O trabalhador tem direito a um dia de folga remunerada por semana, preferencialmente aos domingos, de acordo com a escolha do empregador.</li>
                        <li>Férias Remuneradas: Após cada período de 12 meses de trabalho, o horista tem direito a um período de férias remuneradas, com acréscimo de um terço do salário.</li>
                        <li>13º Salário: O pagamento do décimo terceiro salário é obrigatório e deve ser efetuado em duas parcelas, sendo a primeira até o dia 30 de novembro e a segunda até o dia 20 de dezembro de cada ano.</li>
                        <li>Benefícios Adicionais: Além dos direitos mencionados, o horista também tem direito a outros benefícios previstos na CLT, como o seguro-desemprego, o Fundo de Garantia do Tempo de Serviço (FGTS) e o auxílio-doença, entre outros.</li>
                    </ol>
                </div>

                <div className='fifthNew__question'>
                    <h2>Considerações Finais:</h2>

                    <p>
                        Ao contratar horistas CLT, as empresas devem estar cientes das responsabilidades legais e garantir que todas as regras trabalhistas sejam cumpridas. Isso inclui manter registros precisos de horas trabalhadas, pagar os salários e benefícios corretamente e estar aberto ao diálogo com os trabalhadores para resolver eventuais questões.
                    </p>

                    <p>
                        Por outro lado, os trabalhadores horistas devem conhecer seus direitos e estar atentos para garantir que sejam respeitados pelo empregador.
                    </p>

                    <p>
                        Em resumo, a contratação de horistas CLT oferece flexibilidade para empresas e trabalhadores, mas requer cuidado e atenção às normas estabelecidas pela legislação trabalhista. Ao seguir as regras e garantir uma relação de trabalho justa e transparente, é possível criar um ambiente laboral saudável e produtivo para ambas as partes.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default FifthNew;