import Button from '../Button';
import './Intro.css';


const Intro = () => {
    return (
        <section id="section-intro">
            <div className='section__intro--container'>
                <h1 className='intro__h1'>Contabilidade Especializada para sua empresa</h1>
                <p className='intro__text'>
                    Somos especializados em soluções <span>contábeis, trabalhistas, financeiras, tributárias</span> e
                    <span> paralegais</span> para sua empresa, back-office completo.
                </p>
                <Button name="Entre em contato" />
            </div>
        </section>
    )
}

export default Intro