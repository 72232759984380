import './FirstNew.css';
import React, { useEffect } from 'react';


const FirstNew = () => {

    useEffect(() => {
        const scrollOptions = {
            top: 0,
            behavior: 'smooth'
        };

        window.scrollTo(scrollOptions);
    }, []);

    return (
        <section id='firstNew'>
            <div className='firstNew__container'>
                <h1>CHURN RATE – O que é e porque você deve reduzi-lo</h1>
                <img src='/images/blog/1.jpg' />

                <div className='firstNew__p--container'>
                    <p>Churn é uma métrica que indica o quanto sua empresa perdeu de receita ou clientes.</p>
                    <p>Entender por que seus clientes abandonam o seu produto ou serviço é vital para conquistar um crescimento sustentável.</p>
                    <p>Para calcular o churn, basta dividir a quantidade de clientes que perdeu até o final do período pelo total de clientes que iniciaram.</p>
                    <p>Exemplo: se você perdeu 10 clientes de 100 = 10% de churn rate.</p>
                </div>

                <div className='firstNew__question'>
                    <h2>Qual a taxa ideal de Churn?</h2>
                    <p>
                        A melhor resposta é: o mais baixo possível. Mas entender qual a taxa ideal de churn para um negócio depende de muitos fatores.
                        É quase impossível estimar um valor que seja verdadeiro para todo tipo de negócio.
                    </p>
                </div>

                <div className='firstNew__p--container'>
                    <p>Feedbacks são valiosos e irão ajudar você a entender quais as razões mais frequentes de churn no seu negócio.</p>
                    <p>Fale conosco pelo nosso Whatsapp clicando aqui.</p>
                </div>
            </div>
        </section>
    )
}

export default FirstNew;