import Button from '../Button';
import './Servicos.css';


const Servicos = () => {

    const cardsServicos = [
        {
            id: 1,
            img: '/images/servicos/contabil.jpg',
            serv: 'Contábil',
            text: 'Produzimos relatórios contábeis mensais, para uma melhor tomada de decisões gerenciais.',
        },

        {
            id: 2,
            img: '/images/servicos/trabalhista.jpg',
            serv: 'Trabalhista',
            text: 'Atendemos todas as necessidades da folha de pagamento, recrutamento, seleção e também a todas as obrigações acessórias necessárias.'
        },

        {
            id: 3,
            img: '/images/servicos/fiscal.jpg',
            serv: 'Fiscal',
            text: 'Acompanhamos toda a operação fiscal de sua empresa, atendendo a todas as obrigações necessárias a ela, além de planejar regularmente e acompanhar o melhor regime tributário para sua empresa.'
        }
    ]

    return (
        <section id='servicos'>
            <h2 className='servicos__h2'>Serviços</h2>
            
            <div className='servicos__container'>
                {cardsServicos.map((card) => (
                    <section key={card.id} className='servicos__cards--container'>
                        <img src={card.img} />

                        <div className='servicos__textButton'>
                            <div className='servicos__text'>
                                <h4>{card.serv}</h4>
                                <p>{card.text}</p>
                            </div>

                            <Button name='Saiba mais' />
                        </div>
                    </section>
                ))}
            </div>
        </section>
    )
}

export default Servicos;