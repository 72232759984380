import './Footer.css';
import { FaMapMarkedAlt, FaLinkedinIn, FaMailBulk, FaInstagram, FaWhatsapp } from "react-icons/fa";


const Footer = () => {

    const addresses = [
        {
            id: 1,
            icon: <FaMapMarkedAlt />,
            ref: 'https://www.google.com.br/maps/place/Rua+Luiz+Pimentel,+97+-+Boa+Viagem,+Recife+-+PE,+51030-350/@-8.137829,-34.9083718,17z/data=!3m1!4b1!4m6!3m5!1s0x7ab1fd2a63812bb:0x31448bb91103e526!8m2!3d-8.1378343!4d-34.9057969!16s%2Fg%2F11c0rlkm46?entry=ttu',
            text: 'Rua Luiz Pimentel 97, Boa Viagem, Recife - PE',
        },

        {
            id: 2,
            icon: <FaLinkedinIn />,
            ref: 'https://www.linkedin.com/company/lfm-contabilidade-e-assessoria-empresarial/about/',
            text: 'LFM Contabilidade e Assessoria Empresarial',
        },

        {
            id: 3,
            icon: <FaMailBulk />,
            ref: 'mailto:contato@lfmassessoria.com.br',
            text: 'contato@lfmassessoria.com.br',
        },

        {
            id: 4,
            icon: <FaInstagram />,
            ref: 'https://www.instagram.com/lfm_contabilidade/',
            text: '@lfm_contabilidade',
        },

        {
            id: 5,
            icon: <FaWhatsapp />,
            ref: 'https://wa.me/5581999470025?text=Olá!%20Vocês%20poderiam%20me%20ajudar%20com%20algumas%20informações?',
            text: '(81) 99947-0025',
        }
    ]

    return (
        <section id='footer'>
            <div className='footer__container'>
                <img src='/images/logo.png' className='footer__img' />

                <address className='footer__address'>
                    {addresses.map((address) => (
                        <section key={address.id} className='address__text--container'>
                            <div>{address.icon}</div>
                            <a href={address.ref} className='address__links' target='_blank'>{address.text}</a>
                        </section>
                    ))}
                </address>
            </div>
        </section>
    )
}

export default Footer;