import './Header.css';
import { FaBars, FaXmark } from "react-icons/fa6";
import { useState } from 'react';
import { Link } from 'react-router-dom';


const Header = () => {

    const [openMenu, setOpenMenu] = useState(false);
    const applyChanges = () => {
        setOpenMenu(!openMenu);
    }

    const navbar = [
        {
            id: 1,
            name: 'Início',
            link: '/'
        },

        {
            id: 2,
            name: 'Sobre',
            link: '/Sobre'
        },

        {
            id: 3,
            name: 'Blog',
            link: '/Blog'
        },

        {
            id: 4,
            name: 'Nosso App',
            link: '/NossoApp'
        }
    ]

    return (
        <section id="header">
            <nav className='header__navContainer'>
                <img src='/images/logo.png' className='header__logo' />
                <ul className={!openMenu ? 'header__ul' : 'header__ul open-header'}>
                    {navbar.map((item) => (
                        <li key={item.id} className='header__li' onClick={applyChanges}>
                            <Link to={item.link} className='header__a'>{item.name}</Link>
                        </li>
                    ))}
                </ul>
                <FaBars className={!openMenu ? 'bars' : 'bars bars-off'} onClick={applyChanges} />
                <FaXmark className={!openMenu ? 'xmark xmark-off' : 'xmark'} onClick={applyChanges} />
            </nav>
        </section>
    )
}

export default Header;