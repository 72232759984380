import './Solucoes.css';
import { FaCalculator, FaCheck, FaFolderOpen } from "react-icons/fa6";
import { FaChartLine, FaShoppingCart } from "react-icons/fa";
import { IoChatbubblesSharp } from "react-icons/io5";


const Solucoes = () => {

    return (
        <section id='solucoes'>
            <h2 className='solucoes__h2'>Soluções</h2>
            <div className='solucoes__container'>

                <section className='cards'>
                    <div className='path-style'>
                        <FaShoppingCart className='chart' />
                    </div>

                    <h4>Contabilidade Especializada</h4>
                    <div className='cards__h4--line'></div>
                    <p>Coloque um ponto final em bitributações, multas e outros problemas causados por falta de conhecimento específico no seu segmento.</p>
                </section>

                <section className='cards'>
                    <div className='path-style'>
                        <IoChatbubblesSharp className='chart' />
                    </div>
                    
                    <h4>Consultoria Permanente</h4>
                    <div className='cards__h4--line'></div>
                    <p>A LFM dispõe do serviço de consultoria permanente através de e-mail, whatsapp e telefone.</p>
                </section>

                <section className='cards'>
                    <div className='path-style'>
                        <FaCheck className='chart' />
                    </div>

                    <h4>Due Diligence</h4>
                    <div className='cards__h4--line'></div>
                    <p>A due diligente tem como propósito estabelecer um diagnóstico sobre o índice de compliance tributário da sua empresa.</p>
                </section>

                <section className='cards'>
                    <div className='path-style'>
                        <FaFolderOpen className='chart' />
                    </div>

                    <h4>Gestão Completa de Certidões</h4>
                    <div className='cards__h4--line'></div>
                    <p>Nosso clientes podem ficar despreocupados, pois informamos em tempo real a situação fiscal de sua empresa.</p>
                </section>

                <section className='cards'>
                    <div className='path-style'>
                        <FaChartLine className='chart' />
                    </div>

                    <h4>Planejamento Tributário Anual</h4>
                    <div className='cards__h4--line'></div>
                    <p>Esse serviço analisa detalhadamente qual o melhor regime tributário para as empresas no ano que se inicia.</p>
                </section>

                <section className='cards'>
                    <div className='path-style'>
                        <FaCalculator className='chart' />
                    </div>

                    <h4>Recuperação Tributária</h4>
                    <div className='cards__h4--line'></div>
                    <p>Oferecemos aos nosso clientes o serviço de revisão e recuperação tributária.</p>
                </section>
            </div>
        </section >
    )
}

export default Solucoes;