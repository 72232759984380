import './QuemSomos.css';


const QuemSomos = () => {
    return (
        <section id='quemsomos'>
            <div className='quemsomos__container'>
                <h2 className='quemsomos__title'>Quem somos?</h2>
                <p className='quemsomos__text'>
                    Somos uma equipe formada por especialistas que buscam sempre estar atualizados com os cenários
                    empresariais/legais das empresas no Brasil e no mundo, primando pela excelência, precisão e
                    segurança dos serviços prestados, além de se destacarem por buscar soluções para a otimização
                    dos resultados dos nossos clientes.
                </p>

                <img src='/images/quemSomos/quemSomos.png' className='quemsomos__firstImg' />

                <img src='/images/logo.png' className='quemsomos__secondImg' />

                <p className='quemsomos__secondText'>
                    Hoje já contamos com inteligência artificial financeira, terceirização atividades operacionais
                    na área de Finanças, além de todos os outros serviços Contábeis já conhecidos.
                </p>
            </div>
        </section>
    )
}

export default QuemSomos;