import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import './Blog.css';


const Blog = () => {

    const blogCards = [
        {
            id: 5,
            img: '/images/blog/5.jpg',
            title: 'Contratação de Horistas CLT: Regras e Considerações Importantes',
            text: 'No mercado de trabalho, a contratação de horistas seguindo as regras da Consolidação das Leis do Trabalho (CLT) é uma prática comum e que requer atenção...',
            links: '/FifthNew'
        },

        {
            id: 4,
            img: '/images/blog/4.jpg',
            title: 'CHURN RATE – O que é e porque você deve reduzi-lo.',
            text: 'Churn é uma métrica que indica o quanto sua empresaperdeu de receita ou clientes. Entender por que seus clientes abandonam o seu produto ou serviço...',
            links: '/FourthNew'
        },

        {
            id: 3,
            img: '/images/blog/3.jpg',
            title: 'Receita Federal exclui o ICMS sobre créditos de PIS/COFINS nas aquisições.',
            text: 'Na apuração dos créditos da Contribuição para o PIS/Pasep e da Cofins a descontar, o valor do ICMS destacado na Nota Fiscal deve ser excluído...',
            links: '/SecondNew'
        },

        {
            id: 2,
            img: '/images/blog/2.jpg',
            title: 'Funil de Vendas (Pipeline), é importante?',
            text: 'Também chamado de pipeline, o funil de vendas é um modelo estratégico que mostra a jornada do cliente, desde o primeiro contato dele com a empresa até o fecham...',
            links: '/ThirdNew'
        },

        {
            id: 1,
            img: '/images/blog/1.jpg',
            title: 'O que é “churn rate”?',
            text: 'Para conquistar um novo cliente, diferentes esforços de marketing são demandados. É necessário desenvolver um produto que satisfaça as necessidades...',
            links: '/FirstNew'
        }
    ]

    return (
        <div id='blog'>
            <img src='/images/blog/bg.jpg' className='blog__img' />
            <div className='blog__container'>
                <h1 className='blog__h1'>Blog</h1>

                <div className='servicos__container blog__secondContainer'>
                    {blogCards.map((card) => (
                        <section key={card.id} className='servicos__cards--container blog__cards--container'>
                            <img src={card.img} />

                            <div className='servicos__textButton blog__textButton'>
                                <div>
                                    <h4 className='blog__h4'>{card.title}</h4>
                                    <p className='blog__p'>{card.text}</p>
                                </div>

                                <Link to={card.links} className='blog__link'>
                                    <Button name='Saiba mais' />
                                </Link>
                            </div>
                        </section>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Blog;