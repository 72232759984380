import './NossoApp.css';


const NossoApp = () => {
    return (
        <div id='nossoApp'>
            <div className='nossoApp__container'>
                <h1 className='nossoApp__h1'>Nosso App</h1>
                <div className='nossoApp__bg'></div>

                <section className='nossoApp__section'>
                    <div className='nossoApp__img--container'>
                        <img src='/images/nossoApp/app.png' className='nossoApp__img' />
                    </div>

                    <div className='nossoApp__text--container'>
                        <h2 className='nossoApp__h2'>Dispomos de um aplicativo exclusivo para os nossos clientes</h2>

                        <div className='nossoApp__text--list'>
                            <h3 className='nossoApp__h3'>Tudo na palma da mão 24 horas por dia</h3>

                            <ul className='nossoApp__ul'>
                                <li>Calendário de vencimentos</li>
                                <li>Comunicados</li>
                                <li>Dashboard</li>
                                <li>Documentos legais da empresa</li>
                                <li>Guias de tributos</li>
                                <li>Solicitações</li>
                            </ul>
                        </div>

                        <div className='nossoApp__btn--container'>
                            <a href='https://onelink.to/nj32nn' target='_blank'>
                                <img src='/images/nossoApp/ios-btn.svg' className='nossoApp__btn' />
                            </a>

                            <a href='https://onelink.to/nj32nn' target='_blank'>
                                <img src='/images/nossoApp/android-btn.png' className='nossoApp__btn' />
                            </a>

                            <a href='https://onelink.to/nj32nn' target='_blank'>
                                <img src='/images/nossoApp/windows-btn.png' className='nossoApp__btn' />
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default NossoApp;