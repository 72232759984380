import './Video.css';


const Video = () => {
    return (
        <div>
            <video autoPlay muted loop>
                <source src="/videos/business-man-bg.mp4" type="video/mp4" />
            </video>
        </div>
    )
}

export default Video