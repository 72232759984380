import './Parceiros.css';


const Parceiros = () => {
    return (
        <section id='parceiros'>
            <div className='parceiros__container'>
                <h2 className='parceiros__title'>Parceiros</h2>
                <p className='parceiros__text'>Alguns de nossos clientes que confiam em nosso trabalho.</p>

                <div className='parceiros__images'>
                    <div className='bg__img--container'>
                        <a href='https://www.instagram.com/xerem.restaurante/' target='_blank'>
                            <img src='/images/parceiros/xerem.jpeg' />
                        </a>
                    </div>

                    <div className='bg__img--container'>
                        <a href='http://www.guimaraeselacerda.com.br/' target='_blank'>
                            <img src='/images/parceiros/guimaraes-lacerda.png' />
                        </a>
                    </div>

                    <div className='bg__img--container'>
                        <a href='https://www.culturainglesa.com.br/' target='_blank'>
                            <img src='/images/parceiros/cultura-inglesa' />
                        </a>
                    </div>

                    <div className='bg__img--container'>
                        <a href='https://eccoprime.com.br/?src=GOAds_154038691629_676614205573_g_980552572&utm_source=GOAds&utm_medium=154038691629&utm_content=676614205573&utm_campaign=980552572&utm_term=g&utm_source=googleads&utm_audience=&creative=&device=c&keyword=eccoprime&network=g&keyword=eccoprime&creative=676614205573&gad_source=1&gclid=EAIaIQobChMI1Pu-nJrOhAMVEmJIAB2OdgS7EAAYASAAEgJwy_D_BwE' target='_blank'>
                            <img src='/images/parceiros/ecoprime.png' />
                            <p className='eccoprime-name'>EccoPrime</p>
                        </a>
                    </div>

                    <div className='bg__img--container'>
                        <a href='https://construtiva.eng.br/' target='_blank'>
                            <img src='/images/parceiros/construtiva-engenharia.png' />
                        </a>
                    </div>

                    <div className='bg__img--container'>
                        <a href='https://www.instagram.com/farmaciapremium_/' target='_blank'>
                            <img src='/images/parceiros/farmacia-premium.png' />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Parceiros;