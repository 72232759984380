import './Button.css';
import { Link } from 'react-router-dom';


const Button = (props) => {
    return (
        <div className='button__container'>
            <a href='https://wa.me/5581999470025?text=Olá!%20Vocês%20poderiam%20me%20ajudar%20com%20algumas%20informações?' target='_blank'>{props.name}</a>
            <a className='button__link'>Saiba mais</a>
        </div>
    )
}

export default Button;