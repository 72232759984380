// CSS
import './Home.css'; 


// Components
import Intro from '../../components/Intro';
import Parceiros from '../../components/Parceiros';
import QuemSomos from '../../components/QuemSomos';
import Servicos from '../../components/Servicos';
import Solucoes from '../../components/Solucoes';
import Video from '../../components/Video';


const Home = () => {
    return (
        <div>
            <Video />
            <Intro />
            <QuemSomos />
            <Servicos />
            <Solucoes />
            <Parceiros />
        </div>
    )
}

export default Home;