import './FourthNew.css';
import React, { useEffect } from 'react';


const FourthNew = () => {

    useEffect(() => {
        const scrollOptions = {
            top: 0,
            behavior: 'smooth'
        };

        window.scrollTo(scrollOptions);
    }, []);

    const definicao = [
        {
            id: 1,
            title: 'O que é um “Churn Rate”?',
            text: 'Para conquistar um novo cliente, diferentes esforços de marketing são demandados. É necessário desenvolver um produto que satisfaça as necessidades do mercado, criar canais para distribuí-lo e deslanchar uma comunicação eficiente para promovê-lo junto o público.',
            text2: 'Só depois é que o cliente vai analisar se compra ou não. Comprou? Mais um nome na base de faturamento.',
            text3: 'O problema é que muitas empresas focam apenas na aquisição – e se descuidam da retenção da clientela, o que é imprescindível para manter o caixa saudável. Pesquisas apontam que o custo de aquisição decliente é entre cinco e sete vezes maior do que o de retenção',
            text4: 'Churn Rate é a métrica de marketing que mensura exatamente a taxa de perda de clientes. Sua fórmula consiste no número de clientes que solicitaram cancelamento divididos pelo total de clientes em determinado período. Veja o exemplo:',
        },
    ]

    const usabilidadeEorigem = [

        {
            id: 2,
            title: 'Onde é mais usado',
            text: 'Há mercados em que o churn rate é uma métrica fundamental para mensurar o crescimento sustentável do negócio. Entre esses setores estão os de assinaturas de produtos, como revistas e vinhos; serviços digitais, como Netflix e Spotify; e ecommerce.',
            text2: 'A métrica também pode ser usada por qualquer empresa que oferece produtos e serviços de compra recorrente. Por exemplo, numa barbearia é comum que os clientes homens cortem o cabelo uma vez por mês. Caso o mesmo cliente fique mais de 40 dias sem ir à barbearia, é provável que ele tenha trocado de estabelecimento – e já pode ser considerado um cliente perdido.',
            text3: 'Em 2007, a Anatel determinou que as operadoras de telefonia permitissem aos clientes a portabilidade de linhas. Desde então, a churn rate tem sido largamente utilizada pelas operadoras – uma vez que os clientes podem cancelar o contrato antes de seu término.',
            text4: 'É frequente as empresas oferecerem descontos numa segunda compra ou em renovações de contrato. O problema é que, em longo prazo, os descontos podem diluir a margem de lucro.',
            text5: 'Para reter os clientes, é mais indicado oferecer serviços extras exclusivos e investir em atendimento de pós-venda.',
            text6: 'Manter relacionamento com a clientela também serve para identificar e corrigir rapidamente possíveis queixas, melhorar a experiência do usuário e atenuar efeitos de dissonância cognitiva – aquela sensação de arrependimento que pode acontecer após uma compra complexa ou de alto valor.',
            text7: 'Conforme o tempo de permanência do cliente aumenta, maior será o valor desse cliente para a empresa.'
        },

        {
            id: 3,
            title: 'Origem',
            text: 'Em inglês, rate é taxa. Churn significa misturar, agitar e bater. O termo também é usado como o ato de usar uma batedeira elétrica.',
            text2: 'A expressão serve, por exemplo, para ilustrar o movimento de fazer manteiga, em que os batedores jogam os ingredientes para a borda do recipiente e os trazem de volta ao centro.',
            text3: 'Adaptado ao mundo dos negócios, o termo tem um significado parecido: medir os clientes que estão escapando pelas beiradas do negócio.'
        }
    ]

    return (
        <section id='fourthNew'>
            <div className='fourthNew__container'>
                <img src='/images/blog/4.jpg' />
                {definicao.map((item) => (
                    <section key={item.id} className='definicao__container'>
                        <h1>{item.title}</h1>
                        <p>{item.text}</p>
                        <p>{item.text2}</p>
                        <p>{item.text3}</p>
                        <p>{item.text4}</p>
                    </section>
                ))}

                <div className='calculoChurn'>
                    <p>Quantidade de ativos em Janeiro: 100</p>
                    <p>Quantidade de cancelamentos em Janeiro: 10</p>
                    <p>10 / 100 = 0,1% * 100</p>
                    <p>Churn rate = 10%</p>
                </div>

                {usabilidadeEorigem.map((section) => (
                    <section key={section.id} className='usabilidade__container'>
                        <h2>{section.title}</h2>
                        <p>{section.text}</p>
                        <p>{section.text2}</p>
                        <p>{section.text3}</p>
                        <p>{section.text4}</p>
                        <p>{section.text5}</p>
                        <p>{section.text6}</p>
                        <p>{section.text7}</p>
                    </section>
                ))}
            </div>
        </section >
    )
}

export default FourthNew;